<template>

  <div>
    <!-- Filters -->
    <orders-list-filters
      :status-filter.sync="statusFilter"
      :status-options="paymentStatus"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchPayments"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: Region -->
          <template #cell(region_id)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{ data.item.regionId.toUpperCase() }}</span>
            </div>
          </template>
          <!-- Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveStatusProduct(data.item.status)}`"
              class="text-capitalize"
            >
              {{ mapStatus(data.item.status) }}
            </b-badge>
            <b-badge
              v-if="data.item.is_on_edit"
              pill
              :variant="`light-${resolveStatusProduct('pending')}`"
              class="text-capitalize"
            >
              Edited
            </b-badge>
          </template>
          <!-- Column: CreatedAt -->
          <template #cell(created_at)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.createdAt) }}
            </span>
          </template>
          <!-- Column: CreatedAt -->
          <template #cell(updated_at)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.updatedAt) }}
            </span>
          </template>
          <!-- Column: Action -->
          <template #cell(action)="data">
            <b-link v-if="$can('tracking_read')" @click="showModalTracking(data.item.id)">
              Tracking
            </b-link>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrders"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- <tracking-status
      :is-show-modal-tracking.sync="isShowModalTracking"
      :tracking-object="currentTracking"
    /> -->
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BBadge, BPagination, BOverlay,
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import ProductsListFilters from '@/components/productManager/ProductsListFilters.vue'
import OrdersListFilters from '@/components/orderManager/OrdersListFilters.vue'
// eslint-disable-next-line import/no-cycle
import useOrderList from '@/views/paymentSection/orderList'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { mapGetters } from 'vuex'
import TrackingStatus from '../modal/TrackingStatus.vue'
import { formatDate } from '../../utils/utils'

export default {
  components: {
    BOverlay,
    TrackingStatus,
    OrdersListFilters,
    ProductsListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    vSelect,
  },
  computed: {
    ...mapGetters('order', ['paymentStatus']),
  },
  methods: {
    showModalTracking(value) {
      // store.dispatch('app/fetchTracking', { id: value })
      //   .then(response => {
      //   })
      //   .catch(e => {
      //   })
      this.isShowModalTracking = true
    },
    mapStatus(value) {
      if (this.paymentStatus.find(s => s.value === value)) {
        return this.paymentStatus.find(s => s.value === value).label
      }
      return 'Local'
    },
  },

  setup() {
    const currentTracking = ref(null)
    const isShowModalTracking = ref(false)
    const {
      timeout,
      showTable,
      fetchPayments,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserStatusVariant,
      resolveStatusProduct,
      resolveTypeProduct,
      // Extra Filters
      statusFilter,
      regionFilter,
      typeFilter,
    } = useOrderList()
    return {
      timeout,
      showTable,
      currentTracking,
      isShowModalTracking,
      formatDate,
      // Sidebar
      fetchPayments,
      tableColumns,
      perPage,
      currentPage,
      totalOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveStatusProduct,
      resolveUserStatusVariant,
      resolveTypeProduct,

      // Extra Filters
      statusFilter,
      typeFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
